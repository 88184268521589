import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const SectionAddOnsMaybe = props => {
  const { addOns, intl, marketplaceCurrency } = props;
  const { Money } = sdkTypes;

  return (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.addOnsTitle" />
      </Heading>
      <div className={css.addOnCardWrapper}>
        {addOns.map(addOn => {
          const price = new Money(addOn.price.amount, marketplaceCurrency);
          return (
            <div className={css.addOnCard} key={addOn.title}>
              <p className={css.addOnTitle}>{addOn.title}</p>
              <p className={css.addOnType}>{addOn.type}</p>
              <p className={css.addOnPrice}>{formatMoney(intl, price)}</p>
              <p className={css.addOnDesc}>{addOn.description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionAddOnsMaybe;
